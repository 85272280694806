<template>
  <div class="wrapper">
    <div class="main">
      <div class="top">
        <img src="../assets/images/newLogin/logo.png" />
        <!-- <div class="btns">
          <div class="btn">APP下载</div>
          <div class="btn">返回首页</div>
        </div> -->
      </div>
      <div class="box" :class="chooseShop ? 'choose' : ''">
        <template>
          <div class="triangle" @click="handleBack" v-show="chooseShop"></div>
          <img
            v-show="chooseShop"
            src="../assets/images/newLogin/back.png"
            class="code"
            @click="handleBack"
          />
        </template>
        <template>
          <div class="left" v-show="!chooseShop">
            <img src="../assets/images/newLogin/illustration.png" />
          </div>
          <div class="right" v-show="!chooseShop">
            <div class="login">
              <img
                src="../assets/images/newLogin/QRcode.png"
                class="code"
                style="right: 14px; top: 14px"
                v-if="!forget && !qrcode"
                @click="handleLoginCode"
              />
              <img
                src="../assets/images/newLogin/computer.png"
                class="code"
                style="right: 14px; top: 14px"
                v-if="!forget && qrcode"
                @click="hanldeNoCode"
              />
              <img
                src="../assets/images/newLogin/back.png"
                class="code"
                v-if="forget"
                @click="forget = false"
              />
              <div class="triangle"></div>

              <div class="form">
                <div v-show="!forget && !qrcode">
                  <div class="form-header">
                    <div :class="type === 1 ? 'active' : ''" @click="type = 1">
                      <!-- <img src="../assets/images/newLogin/icon_password_blue.png" style="width: 16px; height: 20px;" v-if="type === 1" />
                    <img src="../assets/images/newLogin/icon_password.png" style="width: 16px; height: 20px;" v-else  /> -->
                      <i
                        class="iconfont iconsuotou"
                        style="
                          font-size: 20px;
                          margin-right: 8px;
                          font-weight: normal;
                        "
                      ></i>
                      密码登录
                    </div>
                    <div :class="type === 2 ? 'active' : ''" @click="type = 2">
                      <!-- <img src="../assets/images/newLogin/icon_sms_blue.png" style="width: 22px; height: 17px;"  v-if="type === 2" />
                    <img src="../assets/images/newLogin/icon_sms.png" style="width: 22px; height: 17px;" v-else  /> -->
                      <i
                        class="iconfont iconduanxin"
                        style="
                          font-size: 16px;
                          margin-right: 8px;
                          font-weight: normal;
                        "
                      ></i>
                      短信登录
                    </div>
                  </div>
                  <el-form :model="form" v-show="type === 1">
                    <el-form-item>
                      <!-- <el-input  v-model="form.username" placeholder="手机号" id="username"></el-input> -->
                      <input
                        v-model="form.username"
                        placeholder="手机号"
                        class="phone"
                      />
                    </el-form-item>
                    <el-form-item>
                      <el-input
                        v-model="form.password"
                        placeholder="密码"
                        :show-password="showPassword"
                        id="password"
                        autocomplete="new-password"
                      ></el-input>
                      <div class="eye" @click="showPassword = !showPassword">
                        <i class="iconfont iconyanjing" v-if="showPassword"></i>
                        <i class="iconfont icontubiao-yanjing" v-else></i>
                      </div>
                    </el-form-item>
                    <div id="captchaBox" v-show="needCheckGt">
                    </div>
                    <el-form-item class="flex">
                      <el-checkbox-group v-model="form.isAuto">
                        <el-checkbox label="auto">30天内自动登录</el-checkbox>
                      </el-checkbox-group>
                      <span class="forget-txt" @click="forget = true"
                        >找回密码</span
                      >
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        style="width: 100%"
                        class="form-btn"
                        @click="handleLogin"
                        :loading="loading"
                        :class="active1 ? '' : 'disabled'"
                      >
                        登录
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <el-form
                    :model="form"
                    v-show="type === 2"
                    style="margin-top: 40px"
                  >
                    <el-form-item>
                      <el-input
                        v-model="form.phone"
                        placeholder="手机号"
                      ></el-input>
                    </el-form-item>
                    <el-form-item class="flex">
                      <el-input
                        v-model="form.code"
                        placeholder="验证码"
                        class="get-phone"
                      ></el-input>
                      <el-button
                        type="primary"
                        class="get-code"
                        @click="getCode('sms_login ')"
                        >{{ time ? time + "s" : "获取验证码" }}</el-button
                      >
                    </el-form-item>
                    <el-form-item class="flex">
                      <el-checkbox-group v-model="form.isAuto">
                        <el-checkbox label="auto">30天内自动登录</el-checkbox>
                      </el-checkbox-group>
                      <span class="forget-txt" @click="forget = true"
                        >找回密码</span
                      >
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        style="width: 100%"
                        class="form-btn"
                        @click="handleLogin"
                        :loading="loading"
                        :class="active2 ? '' : 'disabled'"
                      >
                        登录
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <div v-show="!forget && qrcode">
                  <div style="text-align: center; font-size: 18px">
                    手机扫码 安全登录
                  </div>
                  <div
                    id="qrCodeDiv"
                    ref="qrCodeDiv"
                    style="
                      position: relative;
                      width: 200px;
                      height: 200px;
                      margin: 20px auto 0 auto;
                    "
                  ></div>
                  <div
                    v-if="codeState === 0"
                    style="
                      position: absolute;
                      top: 81px;
                      left: 108px;
                      z-index: 99;
                      background: rgba(255, 255, 255, 0.5);
                      width: 200px;
                      height: 210px;
                    "
                  >
                    <img
                      src="@/assets/images/newLogin/refresh.png"
                      style="margin: 50px 0 0 50px"
                      @click="handleLoginCode"
                    />
                  </div>
                  <div
                    v-if="codeState === 2"
                    style="
                      position: absolute;
                      top: 81px;
                      left: 108px;
                      z-index: 99;
                      background: rgba(255, 255, 255, 0.5);
                      width: 200px;
                      height: 210px;
                    "
                  >
                    <img
                      src="@/assets/images/newLogin/loginSuccess.png"
                      style="margin: 70px 0 0 70px"
                    />
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 16px;
                      margin-top: 40px;
                      color: #555555;
                    "
                    v-if="codeState === 0"
                  >
                    二维码已失效，请<span style="color: #4091fd">刷新</span>
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 16px;
                      margin-top: 40px;
                      color: #555555;
                    "
                    v-if="codeState === 2"
                  >
                    请在手机APP上确认登录
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 16px;
                      margin-top: 40px;
                      color: #555555;
                    "
                    v-if="codeState === 1"
                  >
                    打开<span style="font-weight: Bold">E店宝APP</span
                    >扫一扫登录
                  </div>
                </div>
                <div v-show="forget">
                  <div
                    class="form-header"
                    style="justify-content: center; margin-bottom: 20px"
                  >
                    <div class="active">
                      <img
                        src="../assets/images/newLogin/icon_password_blue.png"
                        style="width: 16px; height: 20px"
                      />
                      找回密码
                    </div>
                  </div>
                  <el-form :model="form">
                    <el-form-item>
                      <el-input
                        v-model="form2.username"
                        placeholder="手机号"
                      ></el-input>
                    </el-form-item>
                    <el-form-item class="flex">
                      <el-input
                        v-model="form2.code"
                        placeholder="请输入验证码"
                        class="get-phone"
                      ></el-input>
                      <el-button
                        type="primary"
                        class="get-code"
                        @click="getCode('sms_reset_pass')"
                        >{{ time ? time + "s" : "获取验证码" }}</el-button
                      >
                    </el-form-item>
                    <el-form-item>
                      <el-input
                        v-model="form2.password"
                        placeholder="请输入密码"
                        show-password
                      ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input
                        v-model="form2.password1"
                        placeholder="请再次输入密码"
                        show-password
                      ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        style="width: 100%"
                        class="form-btn"
                        @click="handleForget"
                        :loading="loading"
                        :class="active3 ? '' : 'disabled'"
                      >
                        提交
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="current">
              <span>当前版本:</span><span> 3.0.0000</span>
              <!-- <span>版本新功能</span> -->
            </div>
          </div>
        </template>
        <template>
          <div class="choose-box" v-show="chooseShop">
            <div class="header">
              <span>选择登录门店</span>
              系统检测到您拥有多个门店账号，请选择登录门店
            </div>
            <div class="shop-box">
              <template v-for="item in msg.mult_shop">
                <div
                  class="shop"
                  :key="item.hierarchy_id"
                  :class="currentId === item.hierarchy_id ? 'shop-active' : ''"
                  @click="currentId = item.hierarchy_id"
                >
                  <div class="img">
                    <img :src="item.merchant_logo" v-if="item.merchant_logo" />
                    <img :src="noImg" v-else />
                  </div>
                  <div class="desc">
                    <div class="title">{{ item.shop_name }}</div>
                    <div class="shop-name">
<!--                      <span>{{ item.merchant_name }}</span>-->
                      <span>{{ item.role_name }}</span>
                    </div>
                  </div>
                  <el-button type="primary" @click="handleEnter(item)"
                    >进入</el-button
                  >
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="name">
        深圳市易店宝数字科技有限公司
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: white"
          >备案号：粤ICP备17042830号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import storage from "good-storage";
import {
  verCodeReq,
  loginReq,
  selectShopReq,
  sendCodeReq,
  reSetpassReq,
  getVersionNewReq,
  getLoginCode,
} from "@/api/newLogin";
import QRCode from "qrcodejs2";

export default {
  name: "NewLogin",
  data() {
    const noImg = require("@/assets/images/no_img.png");
    return {
      needCheckGt: false,
      showPassword: true,
      changeShop: false, // 更换门店的标识，已经登录过的
      noImg,
      active3: false,
      active2: false,
      active1: false,
      smsType: "",
      time: 0,
      captchaObj2: null,
      msg: {},
      currentId: "", // 当前店铺id
      captchaObj: null,
      result: {},
      chooseShop: false,
      forget: false,
      checkGt: false,
      checkGt2: false,
      loading: false,
      qrcode: false,
      codeState: 1,
      codeStatus: null,
      timer: null,
      code: null,
      type: 1,
      form: {
        username: "",
        password: "",
        phone: "",
        geetest_challenge: "",
        geetest_validate: "",
        geetest_seccode: "",
        isAuto: ["auto"],
      },
      form2: {
        username: "",
        code: "",
        password: "",
        password1: "",
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.handleActive();
      },
      deep: true,
    },
    form2: {
      handler() {
        this.handleActive();
      },
      deep: true,
    },
    checkGt: {
      handler() {
        this.handleActive();
      },
      deep: true,
    },
  },
  mounted() {
    // const dom = document.getElementById('username');
    // setTimeout(() => {
    //   dom.focus();
    // }, 800);
    // this.getVersionNew();
    if (this.$route.params.chooseShop) {
      this.chooseShop = true;
      this.msg = {
        mult_shop: storage.session.get("shopList"),
        token: "",
      };
      this.changeShop = true;
      // storage.session.get('tabs') && storage.session.remove('tabs');
      // storage.session.get('currTab') && storage.session.remove('currTab');
    } else {
      Cookies.get("token") && Cookies.remove("token");
      storage.session.get("token") && storage.session.remove("token");
      this.gtInit();
      this.gtInit2();
    }
    if (Cookies.get('errNum') >= 3) {
      this.needCheckGt = true;
    }
  },
  methods: {
    // handleFocus() {
    //   const dom = document.getElementById('phone');
    //   console.log('聚焦');
    //   dom.focus();
    // },
    hanldeNoCode() {
      this.qrcode = false;
      clearInterval(this.timer);
    },
    handleLoginCode() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.codeState = 1;
      this.qrcode = true;
      getLoginCode().then((res) => {
        if (res.code === 1) {
          this.code = res.data.code;
          if (!this.codeStatus) {
            this.codeStatus = new QRCode("qrCodeDiv", {
              text: res.data.code,
              width: 200,
              height: 200,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
          } else {
            this.codeStatus.makeCode(res.data.code);
          }

          this.createSetInterval();
        }
      });
    },
    createSetInterval() {
      this.timer = setInterval(() => {
        getLoginCode({ code: this.code }).then((res) => {
          if (res.code === 1) {
            this.codeState = res.data.state;
            if (res.data.state === 3) {
              this.$message.warning("取消登录");
              this.codeState = 1;
              this.code = null;

              this.handleLoginCode();
            } else if (res.data.state === 4) {
              this.$message.success("登录成功");
              clearInterval(this.timer);
              this.codeStatus.clear();
              // 如果不是多店铺
              if (!res.data.mult_shop.length) {
                // 是否有5天自动登录
                const { isAuto } = this.form;
                if (isAuto.length) {
                  Cookies.set("token", res.data.token, { expires: 30 });
                } else {
                  storage.session.set("token", res.data.token);
                }
                this.$router.replace({
                  path: "/",
                });
              } else {
                this.msg = res.data;
                this.chooseShop = true;
                storage.set("shopList", res.data.mult_shop);
              }
            } else if (res.data.state === 0) {
              clearInterval(this.timer);
            }
          }
        });
      }, 1500);
    },
    getVersionNew() {
      getVersionNewReq({
        equipment: 10,
      }).then((res) => {
        if (res.code === 1) {
        }
      });
    },
    handleActive() {
      if (this.form.username && this.form.password && (this.checkGt || !this.needCheckGt)) {
        this.active1 = true;
      } else {
        this.active1 = false;
      }

      if (this.form.phone && this.form.code) {
        this.active2 = true;
      } else {
        this.active2 = false;
      }

      if (
        this.form2.username &&
        this.form2.code &&
        this.form2.password &&
        this.form2.password1
      ) {
        this.active3 = true;
      } else {
        this.active3 = false;
      }
    },
    handleForget() {
      if (!this.form2.username) {
        this.$message.error("请输入手机号码！");
        return;
      }
      if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.form2.username)) {
        this.$message.error("手机号码验证不通过！");
        return;
      }
      if (!this.form2.code) {
        this.$message.error("请输入验证码！");
        return;
      }
      if (!this.form2.password) {
        this.$message.error("请输入密码！");
        return;
      }
      if (!this.form2.password1) {
        this.$message.error("请再次输入密码！");
        return;
      }
      if (this.form2.password !== this.form2.password1) {
        this.$message.error("两次密码输入不一致！");
        return;
      }
      reSetpassReq({ ...this.form2 }).then((res) => {
        if (res.code === 1) {
          this.$message.success("修改成功");
          this.forget = false;
        }
      });
    },
    gtInit2() {
      verCodeReq().then((data) => {
        initGeetest(
          {
            gt: data.gt,
            challenge: data.challenge,
            offline: !data.success,
            new_captcha: data.new_captcha,
            width: "100%",
            product: "bind",
          },
          (captchaObj) => {
            this.captchaObj2 = captchaObj;
            captchaObj
              .onReady(() => {})
              .onSuccess(() => {
                const result = this.captchaObj2.getValidate();
                this.checkGt2 = true;
                let phone;
                if (!this.forget) {
                  phone = this.form.phone;
                } else {
                  phone = this.form2.username;
                }
                const reqObj = { ...result, phone, type: this.smsType };
                sendCodeReq(reqObj).then((res) => {
                  if (res.code === 1) {
                    this.time = 60;
                    const timer = setInterval(() => {
                      this.time -= 1;
                      if (this.time === 0) {
                        clearInterval(timer);
                      }
                    }, 1000);
                  }
                });
              })
              .onError(() => {
                console.log("失败");
              });
          }
        );
      });
    },
    getCode(smsType) {
      this.smsType = smsType;
      if (this.time) {
        return;
      }
      let phone;
      if (!this.forget) {
        phone = this.form.phone;
      } else {
        phone = this.form2.username;
      }
      if (!phone) {
        this.$message.error("请输入11位手机号");
        return;
      }
      if (phone.length < 11) {
        this.$message.error("请输入11位手机号");
        return;
      }
      this.captchaObj2.verify();
    },
    handleBack() {
      this.qrcode = false;
      this.codeState = 1;
      if(this.codeStatus){
        this.codeStatus.clear();
      }

      if (!this.changeShop) {
        this.chooseShop = false;
        this.gtInit();
        this.checkGt = false;
      } else {
        const tabs = storage.session.get("tabs");
        const currTab = storage.session.get("currTab");
        for (let i = 0; i < tabs.length; i += 1) {
          const item = tabs[i];
          if (item.key === currTab) {
            this.$router.replace({
              path: item.path,
            });
            break;
          }
        }
      }
    },
    handleEnter(item) {
      selectShopReq({
        hierarchy_id: item.hierarchy_id,
        store_user_id: item.store_user_id,
        authorization: this.msg.token,
      }).then((res) => {
        if (res.code === 1) {
          const { isAuto } = this.form;
          if (isAuto.length) {
            Cookies.set("token", res.data, { expires: 30 });
          } else {
            storage.session.set("token", res.data);
          }
          storage.session.get("tabs") && storage.session.remove("tabs");
          storage.session.get("currTab") && storage.session.remove("currTab");
          this.$router.replace({
            path: "/",
          });
          // if (!this.changeShop) {
          //   this.$router.replace({
          //     path: '/',
          //   });
          // } else {
          //   const tabs = storage.session.get('tabs');
          //   const currTab = storage.session.get('currTab');
          //   for (let i = 0; i < tabs.length; i += 1) {
          //     const item = tabs[i];
          //     if (item.key === currTab) {
          //       this.$router.replace({
          //         path: item.path,
          //       });
          //       break;
          //     }
          //   }
          // }
        }
      });
    },
    handleLogin() {
      let loginObj = {};
      if (this.type === 1) {
        loginObj = { ...this.form, ...this.result, type: this.type };
        if (!this.form.username) {
          this.$message.error("请输入手机号码！");
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.form.username)) {
          this.$message.error("手机号码验证不通过！");
          return;
        }
        if (!this.form.password) {
          this.$message.error("请输入密码！");
          return;
        }
        if (!this.checkGt && this.needCheckGt) {
          this.$message.error('请点击按钮进行验证！');
          return;
        }
      } else if (this.type === 2) {
        loginObj = { ...this.form, type: this.type };
        if (!this.form.phone) {
          this.$message.error("请输入手机号码！");
          return;
        }
        if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.form.phone)) {
          this.$message.error("手机号码验证不通过！");
          return;
        }
        if (!this.form.code) {
          this.$message.error("请输入验证码！");
          return;
        }
      }
      this.loading = true;
      loginReq(loginObj)
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            // 如果不是多店铺
            if (!res.data.mult_shop.length) {
              // 是否有5天自动登录
              const { isAuto } = this.form;
              if (isAuto.length) {
                Cookies.set("token", res.data.token, { expires: 30 });
              } else {
                storage.session.set("token", res.data.token);
              }
              this.$router.replace({
                path: "/",
              });
            } else {
              this.msg = res.data;
              this.chooseShop = true;
              storage.set("shopList", res.data.mult_shop);
            }
            // 如果errNum存在
            if (Cookies.get('errNum')) {
              Cookies.remove('errNum');
              this.needCheckGt = false;
            }
          }
        })
        .catch(() => {
          const num = Cookies.get('errNum') ? Number(Cookies.get('errNum')) + 1 : 1;
          Cookies.set('errNum', num , { expires: 1 }); // 1 天后失效
          if (num >= 3) {
            this.needCheckGt = true;
          }
          this.loading = false;
          this.checkGt = false;
          this.captchaObj.reset();
        });
    },
    gtInit() {
      verCodeReq().then((data) => {
        initGeetest(
          {
            gt: data.gt,
            challenge: data.challenge,
            offline: !data.success,
            new_captcha: data.new_captcha,
            width: "100%",
          },
          (captchaObj) => {
            this.captchaObj = captchaObj;
            this.captchaObj.appendTo("#captchaBox");
            this.captchaObj.onSuccess(() => {
              const result = this.captchaObj.getValidate();
              this.result = result;
              this.checkGt = true;
            });
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.phone {
  line-height: 48px;
  width: 324px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 15px;
  box-sizing: border-box;
  background: #f6faff;
  &:focus {
    border: 1px solid #dcdfe6 !important;
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: rgb(190, 190, 190);
  }
}
.eye {
  position: absolute;
  top: 0;
  width: 35px;
  height: 46px;
  top: 1px;
  right: 1px;
  line-height: 48px;
  background: #f6faff;
  text-align: center;
  cursor: pointer;
  color: #666;
}
.disabled {
  background: #eee !important;
  border-color: #eee !important;
}
.code {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.triangle {
  position: absolute;
  top: 0;
  right: 0px;
  width: 0;
  height: 0;
  border-top: 70px solid #2791ff;
  border-left: 70px solid transparent;
  cursor: pointer;
}
.shop-active {
  background: #e9f3ff !important;
  border: 1px solid #54a8ff !important;
}
.choose {
  margin-top: 0px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-48%);
  left: 157px;
}
.forget-txt {
  cursor: pointer;
}
.get-phone {
  /deep/ .el-input__inner {
    border-radius: 4px 0 0 4px !important;
  }
}
.get-code {
  border-radius: 0px;
}
.form-btn {
  height: 46px;
  background: #2892ff;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
}
/deep/ .el-form-item {
  margin-bottom: 15px;
}
/deep/ .el-input__inner {
  height: 48px;
  line-height: 48px;
  border-color: #e4e7eb;
  background: #f6faff;
}

.flex {
  /deep/ .el-form-item__content {
    display: flex;
  }
}

// #captchaBox {
//   height: 48px;
//   background: #F6FAFF;
//   margin-bottom: 15px;
// }

.forget-txt {
  margin-left: auto;
  color: #2791ff;
  font-size: 14px;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  // background: url('../assets/images/newLogin/bg.png');
  background: linear-gradient(rgb(54, 149, 255) 0%, rgb(0, 104, 225) 100%);
  // background-size: 100% 100%;
  .main {
    width: 1350px;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 30px;
    margin: 0 auto;
    position: relative;
    .name {
      color: white;
      font-size: 12px;
      opacity: 0.5;
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      text-align: center;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 198px;
        height: 60px;
      }
      .btns {
        .btn {
          display: inline-block;
          width: 115px;
          height: 32px;
          line-height: 32px;
          font-size: 15px;
          border-radius: 999px;
          text-align: center;
          color: white;
          position: relative;
          cursor: pointer;
          &:nth-child(1) {
            margin-right: 20px;
          }
          &::after {
            content: "  ";
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 200%;
            border: 1px solid #fff;
            border-radius: 999px;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
          }
        }
      }
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 177px;
      .choose-box {
        width: 1036px;
        border-radius: 6px;
        background: white;
        padding: 20px 30px;
        box-sizing: border-box;
        .header {
          font-size: 13px;
          color: #999999;
          span {
            font-size: 17px;
            font-weight: bold;
            color: #0080ff;
            margin-right: 10px;
          }
        }
        .shop-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          max-height: 624px;
          overflow-y: auto;
          margin-top: 20px;
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            /**/
          }
          &::-webkit-scrollbar-track {
            background: rgb(239, 239, 239);
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 10px;
          }
          .shop {
            width: 180px;
            height: 290px;
            margin-bottom: 20px;
            background: #f7f7f7;
            border: 1px solid #e9e9e9;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 10px;
            box-sizing: border-box;
            cursor: pointer;
            .img {
              width: 145px;
              height: 145px;
              background: white;
              border: 1px solid #edeef2;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .desc {
              text-align: center;
              .title {
                font-weight: bold;
                font-size: 15px;
              }
              .shop-name {
                font-size: 13px;
                color: #5b5b5b;
                margin-top: 2px;
              }
            }
          }
        }
      }
      .left {
        img {
          width: 492px;
          height: 338px;
        }
      }
      .right {
        .login {
          width: 416px;
          height: 419px;
          background: white;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          padding: 37px 46px;
          box-sizing: border-box;
          .code {
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 99;
            cursor: pointer;
          }
          .triangle {
            position: absolute;
            top: 0;
            right: 0px;
            width: 0;
            height: 0;
            border-top: 70px solid #2791ff;
            border-left: 70px solid transparent;
            cursor: pointer;
          }
          .form {
            &-header {
              display: flex;
              border-bottom: 1px solid #e9e9e9;
              font-size: 16px;
              color: #999999;
              display: flex;
              align-items: center;
              padding: 0 17px 15px 17px;
              justify-content: space-between;
              margin-bottom: 22px;
              .active {
                color: #2791ff !important;
                font-weight: bold;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  width: 25px;
                  height: 6px;
                  border-radius: 999px;
                  background: #2791ff;
                  bottom: -15px;
                  left: 45px;
                }
              }
              div {
                display: flex;
                align-items: center;
                // transition: all 0.5s;
                cursor: pointer;
                img {
                  margin-right: 8px;
                }
              }
            }
          }
        }
        .current {
          color: white;
          text-align: center;
          line-height: 50px;
          // display: flex;
          justify-content: space-around;
          padding: 0 90px;
          span {
            &:nth-child(1) {
              color: #ffffff;
              opacity: 0.5;
              font-size: 14px;
            }
            &:nth-child(2) {
              color: #ffffff;
              font-size: 16px;
            }
            &:nth-child(3) {
              color: #ffffff;
              font-size: 14px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
